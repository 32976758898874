<template>
  <div class="w-full">
    <card class="p-5">
      <div class="flex justify-start items-center my-4">
        Data control and permissions for specific employees. You can override
        permissions set based on the employees levels.
      </div>
      <template v-slot:footer>
        <CardFooter
          reloadcard
          printcard
          @reload="reload()"
          @sortType="handleSort($event)"
          @searchResult="handleSearch($event)"
          class="mb-2 mt-2"
        />
      </template>
    </card>
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <Table
      :headers="permissionsHeader"
      :items="permissionData"
      class="w-full mt-5 mb-4"
      aria-label="employee permissions table"
      :has-number="false"
      :loading="loadingData"
    >
      <template v-slot:item="{ item }">
        <div class="flex w-full p-2" v-if="item.employeeId">
          <img
            class="mr-2 rounded"
            v-if="item.photo"
            alt="photo"
            style="width: 40px; height: 40px"
            :src="item.data.photo"
          />
          <div
            style="height: 35px; width: 35px; border-radius: 5px"
            class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
            v-else
          >
            {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
          </div>
          <div class="w-auto flex flex-col">
            <p class="text-sm font-semibold text-darkPurple">
              {{ item.data.fname }} {{ item.data.lname }} {{ item.data.mname }}
            </p>
          </div>
        </div>
        <div v-else-if="item.userDesignation">
          <span> {{ item.data.userDesignation.name }} </span>
        </div>
        <div v-else-if="item.orgFunction">
          <span> {{ item.data.orgFunction.name }} </span>
        </div>
        <div v-else-if="item.viewableToAllAdmins">
          <span v-if="item.data.viewableToAllAdmins">
            All data accessible to all Admins
          </span>
          <span v-else> All data accessible only to select Admins </span>
        </div>
        <div
          v-else-if="item.userId"
          class="w-full text-blueCrayola cursor-pointer"
          @click="
            displayModal(
              item.data.fname,
              item.data.lname,
              item.data.photo,
              item.data.userId,
              item.data.viewableToAllAdmins,
              item.data.userDesignation.name,
              item.data.orgFunction.name,
              item.data.permittedAdmins
            )
          "
        >
          <Tag
            class="py-2 px-2 text-blueCrayola text-sm w-full"
            style="background-color: rgba(33, 118, 255, 0.15)"
          >
            Edit
          </Tag>
        </div>
      </template>
    </Table>
    <RightSideBar
      v-if="viewModal"
      @submit="savePermission"
      @close="removeModal()"
      :loading="loadingModal"
      submit="Save"
      :button-class="`bg-dynamicBackBtn text-white rounded`"
      :disabled-button="disableBtn"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Permission and Data Accessibility</p>
      </template>
      <template v-slot:subtitle>
        <div class="w-full border border-dashed p-3 my-4">
          <p class="font-bold text-lg mb-3">Employee Details</p>
          <div class="flex w-full p-2">
            <img
              class="mr-2 rounded"
              v-if="employeePhoto"
              style="width: 80px; height: 80px"
              :src="employeePhoto"
            />
            <div
              style="height: 80px; width: 80px; border-radius: 5px"
              class="
                text-blueCrayola
                border
                text-center
                font-semibold
                pt-2
                mr-2
              "
              v-else
            >
              {{ $getInitials(`${employeeName}`) }}
            </div>
            <div class="w-auto flex flex-col">
              <p class="text-base font-semibold mt-1 text-blueCrayola">
                {{ employeeName }}
              </p>
              <p class="mt-1 text-sm">
                {{ employeeDesignation }}
              </p>
              <p class="mt-1 text-romanSilver uppercase">
                {{ employeeFunction }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <p class="w-full mt-4 text-base text-darkPurple font-semibold">
        Data accessible :
      </p>
      <div class="flex w-full mt-2">
        <RadioButton
          :options="accessibility"
          col-span="col-span-12"
          class="ml-2 text-base w-full text-darkPurple"
          row-gap="gap-y-5"
          space-between="mr-2"
          v-model="toAllAdmins"
        />
      </div>
      <div v-if="viewable"></div>
      <div v-else class="flex w-11/12 flex-col p-3 ml-4 border-l border-dashed">
        <c-select
          placeholder="--Select Admins--"
          :options="adminUsers"
          @onChange="handleAdminSelect($event)"
          variant="w-full mt-1"
          class="mr-2"
          style="width: 400px"
          v-model="adminUsers.id"
        />
        <div
          class="w-full flex mt-2 py-4"
          v-for="(admin, index) in permittedAdmins"
          :key="index"
        >
          <div class="flex flex-grow">
            <div class="w-auto flex flex-col">
              <p class="text-base font-semibold">
                {{ `${admin.name}` }}
              </p>
            </div>
          </div>
          <div class="cursor-pointer" @click="removeAdmin(admin.id)">
            <Tag
              class="py-2 px-2 mx-2 text-desire text-sm w-1/5 h-8"
              style="
                background-color: rgba(234, 60, 83, 0.15);
                text-align: center;
              "
            >
              Remove
            </Tag>
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Loader from "@/components/Loader";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import Tag from "@/components/Tag";
import RadioButton from "@/components/RadioButton";

export default {
  components: {
    Table,
    Loader,
    CSelect,
    CardFooter,
    RightSideBar,
    RadioButton,
    Card,
    Tag,
  },
  data() {
    return {
      disableBtn: false,
      loading: false,
      loadingData: true,
      viewModal: false,
      toAllAdmins: true,
      loadingModal: true,
      response: "",
      responseBg: "",
      success: false,
      permissionsHeader: [
        { title: "Employees", value: "employeeId", width: 20 },
        {
          title: "Designation",
          value: "userDesignation",
          filter: true,
          width: 20,
        },
        { title: "Function", value: "orgFunction", filter: true, width: 20 },
        { title: "Permissions", value: "viewableToAllAdmins", width: 23 },
        { title: "", value: "userId", image: true, width: 20 },
      ],
      permissionData: [],
      accessibility: [
        {
          name: "Accessible to all Admins",
          radioName: "viewableToAllAdmins",
          value: true,
        },
        {
          name: "Select Specific Admins",
          radioName: "viewableToAllAdmins",
          value: false,
        },
      ],
      employeeData: {
        userId: "",
        orgId: "",
        viewableToAllAdmins: "",
        permittedAdmins: [],
      },
      employeeName: "",
      employeeDesignation: "",
      employeeFunction: "",
      employeePhoto: "",
      adminUsers: [],
      permittedAdmins: [],
      viewable: "",
    };
  },
  watch: {
    toAllAdmins(value) {
      if (value === "true" || value) {
        this.employeeData.viewableToAllAdmins = true;
        this.viewable = true;
      }
      if (value === "false" || !value) {
        this.employeeData.viewableToAllAdmins = false;
        this.viewable = false;
      }
    },
  },

  methods: {
    reload() {
      this.getPermissions();
      this.getAdminUsers();
    },

    getPermissions() {
      this.permissionData = [];
      this.$_getPermissions(this.$orgId).then((result) => {
        const permission = result.data.data.employees;
        permission.map((role) => {
          if (role.userDesignation) {
            this.permissionData.push(role);
          }
          return {};
        });
        this.loadingData = false;
      });
    },

    getAdminUsers(permittedUsers) {
      this.adminUsers = [];
      const attributes = `id,fname,lname`;
      this.$_getAdminUsers("humanar", attributes).then((result) => {
        const admins = result.data.users;

        admins.filter((a) => {
          if (a.id !== this.employeeData.userId) {
            const adminSelectData = { id: a.id, name: `${a.fname} ${a.lname}` };
            this.adminUsers.push(adminSelectData);
            this.loadingModal = false;
          }
          return {};
        });

        const arrLength = permittedUsers.length;
        let i;

        for (i = 0; i < arrLength; i++) {
          // eslint-disable-next-line no-loop-func
          admins.map((element) => {
            if (element.id === permittedUsers[i]) {
              this.employeeData.permittedAdmins.push(permittedUsers[i]);
              const newArry = {
                id: `${element.id}`,
                name: `${element.fname} ${element.lname}`,
              };
              this.permittedAdmins.push(newArry);
            }
            return {};
          });
        }
      });
    },

    handleAdminSelect(value) {
      this.adminUsers.map((element) => {
        if (element.id === value) {
          this.employeeData.permittedAdmins.push(value);
          const newArry = { id: `${element.id}`, name: `${element.name}` };
          this.permittedAdmins.push(newArry);
        }
        return {};
      });
    },

    removeAdmin(idnumber) {
      const filteredObj = this.permittedAdmins.filter(
        (obj) => obj.id !== idnumber
      );
      const removeAdmin = this.employeeData.permittedAdmins.indexOf(idnumber);
      // remove object of id (idnumber)
      this.employeeData.permittedAdmins.splice(removeAdmin, 1);
      this.permittedAdmins = filteredObj;
    },

    savePermission() {
      this.disableBtn = true;
      this.employeeData.orgId = this.$orgId;
      if (this.employeeData.viewableToAllAdmins) {
        this.employeeData.permittedAdmins = [];
      }
      this.$_savePermissions(this.employeeData)
        .then((result) => {
          this.$toasted.success(result.data.message, { duration: 5000 });
          this.disableBtn = false;
          this.getPermissions();
          this.getAdminUsers();
          this.removeModal();
        })
        .catch((err) => {
          this.disableBtn = false;
          throw new Error(err);
        });
    },

    async displayModal(
      fname,
      lname,
      photo,
      userId,
      viewableToAllAdmins,
      userDesignation,
      orgFunction,
      permittedAdmins
    ) {
      try {
        await this.$handlePrivilege("permissionHR", "editEmployeePermission");
        this.employeeName = `${fname} ${lname}`;
        this.employeeDesignation = userDesignation;
        this.employeeFunction = orgFunction;
        this.employeePhoto = photo;
        this.employeeData.userId = userId;
        this.employeeData.viewableToAllAdmins = viewableToAllAdmins;
        this.toAllAdmins = viewableToAllAdmins;
        this.viewModal = true;
        this.getAdminUsers(permittedAdmins);
        if (viewableToAllAdmins) {
          this.viewable = true;
        } else {
          this.viewable = false;
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    removeModal() {
      this.employeeName = "";
      this.employeeDesignation = "";
      this.employeeFunction = "";
      this.employeePhoto = "";
      this.employeeData.viewableToAllAdmins = "";
      this.permittedAdmins = [];
      this.adminUsers = [];
      this.employeeData.permittedAdmins = [];
      this.loadingModal = true;
      this.viewModal = false;
    },

    queryUpdate() {
      this.loadingData = true;
      this.permissionData = [];
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `sort=${this.sortType}` : "";
      this.$_getPermissions(`${this.$orgId}?${sort}${search}`).then(
        (result) => {
          const permission = result.data.data.employees;
          permission.map((role) => {
            if (role.userDesignation) {
              this.permissionData.push(role);
            }
            return {};
          });
          this.loadingData = false;
        }
      );
    },

    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
  },
  mounted() {
    this.getPermissions();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
